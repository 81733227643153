import styled from 'styled-components';

export const FormWrap = styled.form`
    position: relative;
    .form-output{
        margin-top: 10px;
        &.success{
            color: green;
        }
        &.errorMsg{
            color: red;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid #e6dad2;
            -webkit-text-fill-color: #696969;
            -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset;
            transition: background-color 5000s ease-in-out 0s;
        // @media (prefers-color-scheme: light) {
        //     border: 1px solid #e6dad2;
        //     -webkit-text-fill-color: #696969;
        //     -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset;
        //     transition: background-color 5000s ease-in-out 0s;
        // }
        @media (prefers-color-scheme: dark) {
            border: 1px solid #3c3c3c;
            -webkit-text-fill-color: #696969;
            -webkit-box-shadow: 0 0 0px 1000px #121212 inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
`;